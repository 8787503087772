import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

import { imageError } from '@/shared/constants/icons';
import { reactive, computed, onUnmounted, onMounted, watch } from 'vue';

interface State {
  src: string;
}

interface Props {
  imageUrl: string;
  imageAlt: string;
  imageError?: string;
  width?: string;
  height?: string;
  radius?: string;
  imagePlaceholder?: string;
}

interface Emits {
  (e: 'update:load'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppImage',
  props: {
    imageUrl: {},
    imageAlt: {},
    imageError: { default: imageError },
    width: { default: '' },
    height: { default: '' },
    radius: { default: '13px' },
    imagePlaceholder: { default: '' }
  },
  emits: ["update:load"],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "49d72da8": (_ctx.radius),
  "01cd9428": (_ctx.width),
  "6b82e47e": (_ctx.height)
}))

const props = __props;

const state = reactive<State>({
  src: '',
});

const emit = __emit;

const loading = computed<boolean>(
  () => props.imagePlaceholder !== '' && state.src === props.imagePlaceholder,
);

function changeLoad(): void {
  if (props.imagePlaceholder !== '' && state.src === props.imagePlaceholder) {
    state.src = props.imageUrl;
  } else if (state.src === props.imageUrl) {
    emit('update:load');
  }
}

watch(
  () => props.imageUrl,
  (newValue: string) => {
    state.src = newValue;
  },
);

onMounted(() => {
  state.src =
    props.imagePlaceholder !== '' ? props.imagePlaceholder : props.imageUrl;
});

onUnmounted(() => {
  state.src = props.imagePlaceholder ?? '';
});

function handleLoadError(): void {
  state.src = props.imageError;
  emit('update:load');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    loading: "eager",
    src: state.src,
    alt: props.imageAlt,
    class: _normalizeClass([
      'app-image',
      {
        'app-image--loading': loading.value,
        'app-image--size': _ctx.width !== '' && _ctx.height !== '',
      },
    ]),
    onLoad: changeLoad,
    onError: handleLoadError
  }, null, 42, _hoisted_1))
}
}

})