import { defineStore } from 'pinia';
import { ScanQrCodeRequest } from '@/shared/types/qrCode';
import qrCodeService from '@/shared/services/qrCodeService/qrCodeService';

export default defineStore({
  id: 'qrCode',

  actions: {
    async scanQrCode(request: ScanQrCodeRequest) {
      await qrCodeService.scanQrCode(request);
    },
  },
});
