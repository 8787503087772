import { ToastType } from '@/shared/types/utils';
import { toastController, ToastOptions } from '@ionic/vue';
import {
  toastDanger,
  toastInformation,
  toastMedal,
  toastSuccess,
  toastWarning,
} from '@/shared/constants/icons';
import useSharedStore from '@/store/shared/useSharedStore';

/**
 * Creates a notification toast using [Ionic's toast API.]{@link https://ionicframework.com/docs/api/toast}
 * @param message The message to be displayed inside the toast.
 * @param type The [type]{@link ToastType} of toast to be created.
 * @param position The position of the toast when displayed. For more information see {@link ToastOptions.position}.
 * @param duration The amount of time that the notification will be displayed (in milliseconds).
 */

export default async function notify(
  message: string,
  type: ToastType = 'info',
  position: ToastOptions['position'] = 'bottom',
  duration = 4000,
) {
  const iconsByType: Record<ToastType, string> = {
    info: toastInformation,
    success: toastSuccess,
    warning: toastWarning,
    danger: toastDanger,
    achievement: toastMedal,
  };

  const options: ToastOptions = {
    icon: iconsByType[type],
    cssClass: `app-toast app-toast--${type}`,
    duration,
    position,
    message,
  };

  if (type === 'success') {
    options.icon = toastSuccess;
  }
  const sharedStore = useSharedStore();
  const toast = await toastController.create(options);
  sharedStore.setIsNotifyActive(true);

  toast.onclick = () => {
    toast.dismiss();
    sharedStore.setIsNotifyActive(false);
  };

  await toast.present();
}
