import { defineStore } from 'pinia';
import {
  StoreItem,
  PurchasedItem,
  CategorizedOption,
  CollectablesRequest,
  CollectablesResponse,
  CollectableType,
} from '@/shared/types/store';
import storeService from '@/shared/services/storeService/storeService';
import useProfileStore from '../profile/useProfileStore';

interface State {
  purchasedItems: PurchasedItem[];
  isCurrentBalanceVisible: boolean;
  collectableCategories: CategorizedOption[];
  prizes: StoreItem[];
  collectables: CollectablesResponse;
  collectableTypes: CollectableType[];
}

export default defineStore({
  id: 'store',

  state: (): State => ({
    purchasedItems: [],
    isCurrentBalanceVisible: true,
    collectableCategories: [],
    prizes: [],
    collectables: {} as CollectablesResponse,
    collectableTypes: [],
  }),

  actions: {
    async purchaseItem(reference: string): Promise<void> {
      const profileStore = useProfileStore();

      if (profileStore.userProfileDetails === undefined) {
        return;
      }

      profileStore.userProfileDetails.coins = (
        await storeService.purchaseStoreItem(reference)
      ).coins;

      this.updatePurchasedItem(reference);
    },

    updatePurchasedItem(reference: string): void {
      if (this.collectables.numberOfPurchased) {
        this.collectables.numberOfPurchased += 1;

        const item = this.collectables.items.find(
          (collectableItem) => collectableItem.reference === reference,
        );

        if (item) {
          item.purchased = true;
        }
      }

      this.collectableCategories.forEach((category) => {
        const categoryItem = category.storeItems.find(
          (collectableItem) => collectableItem.reference === reference,
        );

        if (categoryItem) {
          categoryItem.purchased = true;
        }
      });
    },

    async getPurchasedItems(): Promise<void> {
      try {
        this.purchasedItems = (await storeService.getPurchasedItems()).results;
      } catch (e) {
        this.purchasedItems = [];
      }
    },

    async loadCollectableCategories(
      collectableType: string,
      limit: number,
    ): Promise<void> {
      try {
        this.collectableCategories = (
          await storeService.getCollectablesCategories(collectableType, limit)
        ).results;
      } catch {
        this.collectableCategories = [];
      }
    },

    async loadPrizes(): Promise<void> {
      try {
        this.prizes = (await storeService.getPrizes()).results;
      } catch {
        this.prizes = [];
      }
    },

    async loadCollectables(request: CollectablesRequest): Promise<void> {
      try {
        this.collectables = await storeService.getCollectables(request);
      } catch {
        this.collectables = {} as CollectablesResponse;
      }
    },

    resetCollectables(): void {
      this.collectables = {} as CollectablesResponse;
    },

    resetCollectableCategories(): void {
      this.collectableCategories = [];
    },

    async loadCollectableTypes() {
      try {
        this.collectableTypes = (
          await storeService.getCollectablesTypes()
        ).results;
      } catch {
        this.collectableTypes = [];
      }
    },
  },
});
