import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "app-empty-state" }
const _hoisted_2 = {
  class: "app-empty-state__body",
  "data-testid": "app-empty-state-body"
}
const _hoisted_3 = {
  class: "app-empty-state__body__title",
  "data-testid": "app-empty-state-title"
}
const _hoisted_4 = {
  key: 0,
  class: "app-empty-state__body__subtitle",
  "data-testid": "app-empty-state-subtitle"
}

import { type Component } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  icon: Component;
  title: string;
  subtitle?: string;
  subtitleParameters?: Array<string>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppEmptyState',
  props: {
    icon: {},
    title: {},
    subtitle: {},
    subtitleParameters: {}
  },
  setup(__props: any) {



const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
      name: "empty-state",
      "data-testid": "app-empty-state-image"
    })),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(_ctx.title)), 1),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(t)(_ctx.subtitle, _ctx.subtitleParameters || [])), 1))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})