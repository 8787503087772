import axios, { InternalAxiosRequestConfig } from 'axios';
import { BusinessError, ResponseError } from '@/shared/types/utils';
import useErrorStore from '@/store/error/useErrorStore';
import usePostHogEvents from '@/composables/usePostHogEvents/usePostHogEvents';
import useTokenStore from '@/store/token/useTokenStore';
import { HttpStatus } from '@/shared/types/generic';

function decodeJWT(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

api.interceptors.request.use(
  async (
    request: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig<unknown>> => {
    const tokenStore = useTokenStore();

    if (tokenStore.token) {
      try {
        const jwt = decodeJWT(tokenStore.token);
        if (jwt.exp * 1000 < Date.now()) {
          await tokenStore.refreshUserToken();
        }
      } catch (e) {
        tokenStore.$reset();
      }
    }

    const requestConfig: InternalAxiosRequestConfig = { ...request };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    requestConfig.headers = {
      ...requestConfig.headers,
      'Source-Id': '1',
      Time: Date.now(),
      Authorization: `Bearer ${tokenStore.token}`,
      'K-Engage-Worker-Version': process.env.VUE_APP_VERSION || '',
      'User-Id': tokenStore.b2cId,
      'Session-Id': usePostHogEvents().postHogSessionId(),
    };

    return requestConfig;
  },
);

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const errorStore = useErrorStore();

    errorStore.setErrorCode(error.response?.status);

    if (error.response?.status === HttpStatus.LOCKED_USER) {
      errorStore.setLockErrorMessage(error.response.data);
    }

    const isBusinessError =
      axios.isAxiosError(error) &&
      ResponseError.isABusinessError(error.response?.data);

    if (isBusinessError && error.response?.status !== HttpStatus.LOCKED_USER) {
      throw new ResponseError(
        error.response?.status || 400,
        error.response?.data as BusinessError,
      );
    }

    throw error;
  },
);

export default api;
