import {
  ChallengeGoalStaticReference,
  ChallengeModeStaticReference,
  ChallengeTypeStaticReference,
  Challenge,
  LastCompletedChallenges,
  ChallengeAudienceStaticReference,
  ChallengeDetails,
  Team,
  RecurrencyConfigsOptions,
} from '@/shared/types/challenges';
import {
  placeholderImage,
  dumyChallengeBackground,
} from '@/shared/constants/icons';
import {
  getRandomNumber,
  DatePeriod,
} from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { StatusStaticReference } from '@/shared/types/generic';
import getRandomReference from '@/shared/helpers/getRandomReference/getRandomReference';

export const lastCompletedChallenges = (
  amountOfChallenges = 1,
): LastCompletedChallenges => {
  if (amountOfChallenges === 1) {
    return {
      totalCurrentCoins: 100,
      earnedCoins: 10,
      totalCurrentPoints: 100,
      earnedPoints: 10,
      challengePosition: 3,
      challengeUnitMeasureName: 'unitMeasure.quantity',
      challengeMetricOrCategoryName: 'Test category name',
      challengeNumberOfUnits: 50,
      challengeDays: 5,
      finishedChallenges: amountOfChallenges,
    };
  }

  return {
    totalCurrentCoins: 100,
    earnedCoins: Math.round(Math.random() * amountOfChallenges),
    totalCurrentPoints: 100,
    earnedPoints: Math.round(Math.random() * amountOfChallenges),
    numberOfWins: 3,
    finishedChallenges: amountOfChallenges,
  };
};

export const mockChallenge = (
  goalStaticReference?: ChallengeGoalStaticReference,
  audienceStaticReference = ChallengeAudienceStaticReference.INDIVIDUAL,
  modeStaticReference = ChallengeModeStaticReference.PLAYER_VS_PLAYER,
  typeStaticReference = ChallengeTypeStaticReference.CLASSIC,
  statusStaticReference = StatusStaticReference.NOT_STARTED,
  hasFinished = false,
): Challenge => {
  const currentTime: number = new Date().getTime();

  const endTime: number =
    currentTime +
    [DatePeriod.HOUR * 0.1, DatePeriod.HOUR * 0.2, DatePeriod.HOUR * 0.3][
      getRandomNumber(2)
    ];

  const startTime = (): number => {
    if (hasFinished) {
      return endTime;
    }
    if (statusStaticReference === StatusStaticReference.ON_GOING) {
      return currentTime - DatePeriod.HOUR;
    }
    return currentTime + DatePeriod.HOUR;
  };

  const targetAmount =
    goalStaticReference !== ChallengeGoalStaticReference.LEAST_POINTS_WIN &&
    goalStaticReference !== ChallengeGoalStaticReference.MOST_POINTS_WIN
      ? 100
      : undefined;

  const coinsPerUnit =
    typeStaticReference === ChallengeTypeStaticReference.ACHIEVER
      ? getRandomNumber(100)
      : undefined;

  return {
    imageUrl: dumyChallengeBackground,
    name: '{name}',
    unitOfMeasureName: 'unitMeasure.quantity',
    metricName: '{metric name}',
    reference: getRandomReference(),
    targetAmount,
    potentialCoins: 100,
    coinsPerUnit,
    endTime,
    startTime: startTime(),
    typeStaticReference,
    modeStaticReference,
    goalStaticReference,
    statusStaticReference,
    audienceStaticReference,
    coinsEarned: coinsPerUnit && coinsPerUnit * 10,
    goalStatement: 'statement',
  };
};

export const mockTeams = (): Team[] => [
  {
    name: 'Test team name',
    image: placeholderImage,
    units: 25,
    yourTeam: true,
    imageUrl: placeholderImage,
  },
  {
    name: 'Test team name 2',
    image: placeholderImage,
    units: 40,
    yourTeam: false,
    imageUrl: placeholderImage,
  },
];

export const mockLeaderboard = (): ChallengeDetails['leaderboard'] => [
  {
    position: 1,
    urlAvatar: placeholderImage,
    name: 'my user',
    units: 1,
    currentUser: false,
    username: 'Test user name',
  },
  {
    position: 2,
    urlAvatar: placeholderImage,
    units: 2,
    currentUser: true,
    username: 'Test user name',
  },
  {
    position: 3,
    urlAvatar: placeholderImage,
    units: 0,
    currentUser: true,
    username: 'Test user name',
  },
];

export const mockChallengeDetails = (): ChallengeDetails => ({
  ...mockChallenge(
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeTypeStaticReference.CLASSIC,
    StatusStaticReference.NOT_STARTED,
  ),
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
  numberOfParticipants: 100,
  cadence: RecurrencyConfigsOptions.DAY,
  totalCoins: 600,
  totalPoints: 500,
  leaderboard: mockLeaderboard(),
  units: 40,
  pointsPerDay: 100,
  metricDescription: 'Test metric description',
  teams: mockTeams(),
  conclusionReason: 'machine broke',
  conclusionDate: new Date().getTime(),
  calculationTypeName: 'calculationType.sum',
});

const newChallengePossibleCombinations: [
  ChallengeGoalStaticReference | undefined,
  ChallengeAudienceStaticReference,
  ChallengeModeStaticReference,
  ChallengeTypeStaticReference,
  StatusStaticReference,
][] = [
  [
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeTypeStaticReference.CLASSIC,
    StatusStaticReference.NOT_STARTED,
  ],
  [
    ChallengeGoalStaticReference.LEAST_POINTS_WIN,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeTypeStaticReference.CLASSIC,
    StatusStaticReference.ON_GOING,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeTypeStaticReference.FASTEST,
    StatusStaticReference.ON_GOING,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.TOGETHER_AS_TEAM,
    ChallengeTypeStaticReference.REACHED_AS_TEAM,
    StatusStaticReference.ON_GOING,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.SINGLE_PLAYER,
    ChallengeTypeStaticReference.ACHIEVER,
    StatusStaticReference.ON_GOING,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_COOPERATION,
    ChallengeTypeStaticReference.REACHED_AS_TEAM,
    StatusStaticReference.ON_GOING,
  ],

  [
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_DUEL,
    ChallengeTypeStaticReference.CLASSIC,
    StatusStaticReference.ON_GOING,
  ],
  [
    ChallengeGoalStaticReference.LEAST_POINTS_WIN,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_DUEL,
    ChallengeTypeStaticReference.CLASSIC,
    StatusStaticReference.ON_GOING,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_DUEL,
    ChallengeTypeStaticReference.FASTEST,
    StatusStaticReference.ON_GOING,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_COOPERATION,
    ChallengeTypeStaticReference.REACHED_AS_TEAM,
    StatusStaticReference.ON_GOING,
  ],
];

export const openChallenges: Challenge[] = newChallengePossibleCombinations.map(
  (parameters) => mockChallenge(...parameters),
);

export const completedChallenges: Challenge[] =
  newChallengePossibleCombinations.map((parameters) =>
    mockChallenge(...parameters, true),
  );
