import { defineStore } from 'pinia';
import { LeaderboardItem } from '@/shared/types/leaderboard';
import leaderboardService from '@/shared/services/leaderboardService/leaderboardService';

export default defineStore({
  id: 'leaderboard',

  state: () => ({
    topItems: [] as LeaderboardItem[],
    listItems: [] as LeaderboardItem[],
    leaderboard: [] as LeaderboardItem[],
    currentUser: {} as LeaderboardItem,
    totalPlayers: 0,
  }),

  getters: {
    leaderboardItemsByReference(): Record<string, LeaderboardItem> {
      return this.leaderboard.reduce(
        (
          acc: Record<string, LeaderboardItem>,
          leaderboardItem: LeaderboardItem,
        ) => {
          acc[leaderboardItem.reference] = leaderboardItem;

          return acc;
        },
        {},
      );
    },
  },

  actions: {
    async loadLeaderboard() {
      try {
        const { leaderboard, totalPlayers, currentUser } = (
          await leaderboardService.getLeaderboard()
        ).results;

        this.leaderboard = leaderboard;

        this.totalPlayers = totalPlayers;

        this.currentUser = currentUser;

        this.topItems = leaderboard.slice(0, 3);

        this.listItems = leaderboard.slice(3);
      } catch (error) {
        this.totalPlayers = 0;

        this.topItems = [];

        this.listItems = [];

        this.leaderboard = [];
      }
    },
  },
});
