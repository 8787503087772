import axios from '@/plugins/http/axios';

export default {
  getTermsAndConditions(currentDate: number): Promise<File> {
    return axios.get('/terms-and-conditions/document', {
      params: { currentDate },
      responseType: 'arraybuffer',
    });
  },

  acceptTermsAndConditions(): Promise<void> {
    return axios.patch('/users/accept-terms', {
      termsAndConditions: true,
      privacyPolicy: true,
    });
  },
};
