import i18n from '@/plugins/i18n/i18n';
import { Locale } from '@/shared/types/generic';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
  isThisWeekTimezone,
  isThisYearTimezone,
  isTodayTimezone,
  isYesterdayTimezone,
} from '@/shared/helpers/dateFnsTimezones/dateFnsTimezones';
import { DatePeriod } from '@/shared/types/utils';
import getDateFnsLang from '../getDateFnsLang/getDateFnsLang';

export function formatDateToString(
  date: Date | number,
  mask: string,
  timeZone: string,
) {
  const dateFnsLocale = getDateFnsLang(i18n.global.locale.value as Locale);
  if (!timeZone) {
    return format(date, mask, { locale: dateFnsLocale });
  }
  return formatInTimeZone(date, timeZone, mask, { locale: dateFnsLocale });
}

/**
 * Formats a given date according to the [KDS Date Format rules](https://koerberdigital.atlassian.net/wiki/spaces/TRIB/pages/3528426097/Ux+best+practices+Product+guidelines#Date-format).
 * @param date The date in milliseconds to which will be applied the KDS Date Format rules.
 * @return A string, representing the given date, with the KDS Date Format rules applied.
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const today: string = i18n.global.t('common.dates.today');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const yesterday: string = i18n.global.t('common.dates.yesterday');

export function formatDate(date: number, timeZone: string): string {
  if (isThisWeekTimezone(date, timeZone)) {
    if (isTodayTimezone(date, timeZone)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${today}${formatDateToString(
        date,
        i18n.global.t('common.format.date.sameWeek.today'),
        timeZone,
      )}`;
    }

    if (isYesterdayTimezone(date, timeZone)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${yesterday}${formatDateToString(
        date,
        i18n.global.t('common.format.date.sameWeek.yesterday'),
        timeZone,
      )}`;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return `${formatDateToString(
      date,
      i18n.global.t('common.format.date.sameWeek.default'),
      timeZone,
    )}`;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isThisYearTimezone(date, timeZone)) {
    return formatDateToString(
      date,
      i18n.global.t('common.format.date.sameYear'),
      timeZone,
    );
  }

  return formatDateToString(
    date,
    i18n.global.t('common.format.date.default'),
    timeZone,
  );
}

export function formatSurveyDate(surveyDate: number, timeZone: string) {
  return formatDateToString(
    surveyDate,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n.global.t('common.format.date.surveyFullFormat'),
    timeZone,
  );
}

export function formatDateActivity(date: number, timeZone: string): string {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isTodayTimezone(date, timeZone)) {
    return today;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isYesterdayTimezone(date, timeZone)) {
    return yesterday;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isThisYearTimezone(date, timeZone)) {
    return formatDateToString(
      date,
      i18n.global.t('common.format.activityDateFormats.thisYear'),
      timeZone,
    );
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return formatDateToString(
    date,
    i18n.global.t('common.format.activityDateFormats.default'),
    timeZone,
  );
}

export function timeAgo(timestamp: number): string {
  const now = new Date().getTime();

  const diff = now - timestamp;

  if (diff < DatePeriod.HOUR) {
    const minutes = Math.floor(diff / DatePeriod.MINUTE);
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
  }
  if (diff < DatePeriod.DAY) {
    const hours = Math.floor(diff / DatePeriod.HOUR);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  const days = Math.floor(diff / DatePeriod.DAY);
  return `${days} day${days > 1 ? 's' : ''} ago`;
}
