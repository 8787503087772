import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "app-header"
}
const _hoisted_2 = {
  "data-testid": "app-header-title",
  class: "app-header__title"
}
const _hoisted_3 = {
  key: 1,
  class: "app-header__trailing"
}
const _hoisted_4 = { class: "app-header__notifications-icon-container" }
const _hoisted_5 = {
  key: 0,
  class: "app-header__red-dot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppProfileHeader = _resolveComponent("AppProfileHeader")!
  const _component_VueIcon = _resolveComponent("VueIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_IconNotifications = _resolveComponent("IconNotifications")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_IconThreeDots = _resolveComponent("IconThreeDots")!
  const _component_AppTabs = _resolveComponent("AppTabs")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([
      'app-header__container',
      { 'app-header__container--thin': _ctx.isType(_ctx.AppHeaderType.THIN) },
    ])
  }, [
    (_ctx.isType(_ctx.AppHeaderType.PROFILE))
      ? (_openBlock(), _createBlock(_component_AppProfileHeader, {
          key: 0,
          title: _ctx.title,
          onGoBack: _ctx.goBack
        }, null, 8, ["title", "onGoBack"]))
      : (_openBlock(), _createElementBlock("section", _hoisted_1, [
          (_ctx.isType(_ctx.AppHeaderType.THIN))
            ? (_openBlock(), _createBlock(_component_AppButton, {
                key: 0,
                type: "empty",
                "text-color": "black",
                class: "app-header__back-button",
                onClick: _ctx.goBack
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_VueIcon, {
                    path: _ctx.mdiArrowLeft,
                    type: "mdi"
                  }, null, 8, ["path"])
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t(_ctx.title)), 1),
          (_ctx.isType(_ctx.AppHeaderType.BASE))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.isFeatureEnabled(_ctx.Feature.NOTIFICATION_CENTER))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: "/notification-center",
                      class: "app-header__links"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_IconNotifications, { id: "vaibe-ftx__notification-center" }),
                          (!_ctx.notificationCenterStore.allRead)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_router_link, {
                  to: "/more",
                  class: "app-header__links"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_IconThreeDots, { id: "vaibe-ftx__more" })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "app-header__trailing__target" }, null, -1)),
    (_ctx.hasTabs)
      ? (_openBlock(), _createBlock(_component_AppTabs, {
          key: 2,
          class: "app-header__tabs",
          tabs: _ctx.tabs,
          outlined: true,
          "replace-route": ""
        }, null, 8, ["tabs"]))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "app-header__bottom-target" }, null, -1))
  ], 2))
}