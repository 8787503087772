import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ProfileBanner from '@/components/profile/ProfileBanner/ProfileBanner.vue';
import { WHITE } from '@/shared/constants/colors';
import IconArrowBack from '@/assets/icons/icon-arrow-back.vue';
import AppButton from '../AppButton/AppButton.vue';

interface Props {
  title: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppProfileHeader',
  props: {
    title: {}
  },
  emits: ['goBack'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ProfileBanner, { title: _ctx.title }, {
    leading: _withCtx(() => [
      _createVNode(AppButton, {
        type: "empty",
        class: "app-profile-header__back-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('goBack')))
      }, {
        default: _withCtx(() => [
          _createVNode(IconArrowBack, {
            color: _unref(WHITE),
            alt: "Go back"
          }, null, 8, ["color"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})