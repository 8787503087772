import i18n from '@/plugins/i18n/i18n';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

export type ValidationsType = {
  (arg0: string): string;
};

export interface ValidationResult<T> {
  input?: T;
  message: string;
  isValid: boolean;
}

export type Validator<T> = (input: T) => ValidationResult<T>;

export function validate<T>(
  input: T,
  ...validators: Validator<T>[]
): ValidationResult<T>[] {
  return validators.map((validator: Validator<T>) => validator(input));
}

export function areValidationsValid<T>(
  validationResults: ValidationResult<T>[],
): boolean {
  return validationResults.length
    ? validationResults.every((validationResult) => validationResult.isValid)
    : true;
}

export function isGreaterThan(comparator: number): Validator<string> {
  return (input: string): ValidationResult<string> => {
    const isValid: boolean = input.length <= comparator;

    return {
      message: isValid
        ? ''
        : i18n.global.t('common.errors.inputValidations.maximumCharacters', [
            comparator,
          ]),

      input,
      isValid,
    };
  };
}

export function isLessThan(comparator: number): Validator<string> {
  return (input: string): ValidationResult<string> => {
    const isValid: boolean = input.length >= comparator;

    return {
      message: isValid
        ? ''
        : i18n.global.t('common.errors.inputValidations.minimumCharacters', [
            comparator,
          ]),
      input,
      isValid,
    };
  };
}

export const applyValidationsToInput = (
  inputToBeValidated: string,
  listOfValidations: ValidationsType[],
): Array<string> => {
  return listOfValidations
    .map((validation) => validation(inputToBeValidated))
    .filter(Boolean);
};

export function hasSpecialCharacters(): Validator<string> {
  return (input: string): ValidationResult<string> => {
    const isValid = /^[a-zA-Z 0-9-_.]*$/.test(input);
    return {
      message: isValid
        ? ''
        : i18n.global.t('common.errors.inputValidations.specialCharacters'),

      input,
      isValid,
    };
  };
}

export function wordLimit(comparator: number): Validator<string> {
  return (input: string): ValidationResult<string> => {
    const isValid = (input?.match(/(\w+)/gi)?.length || 0) <= comparator;

    return {
      message: isValid
        ? ''
        : i18n.global.t('common.errors.inputValidations.maximumWords', [
            comparator,
          ]),
      input,
      isValid,
    };
  };
}

export function isEmpty(payload: unknown): boolean {
  return (
    payload === null ||
    payload === undefined ||
    (typeof payload === 'string' && payload.trim().length === 0) ||
    (Array.isArray(payload) && payload.length === 0) ||
    (typeof payload === 'object' &&
      !Array.isArray(payload) &&
      Object.keys(payload).length === 0)
  );
}

export const isIOSPlatform = /iPad|iPhone|iPod/.test(navigator.userAgent);
