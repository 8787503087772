import useTokenStore from '@/store/token/useTokenStore';
import usePostHogEvents from '@/composables/usePostHogEvents/usePostHogEvents';
import { useRouter } from 'vue-router';
import { Locale, Routes } from '@/shared/types/generic';
import i18n from '@/plugins/i18n/i18n';
import useErrorStore from '@/store/error/useErrorStore';
import useChallengesStore from '@/store/challenges/useChallengesStore';
import useKudosStore from '@/store/kudos/useKudosStore';
import useProfileStore from '@/store/profile/useProfileStore';
import useQrCodeStore from '@/store/qrCode/useQrCodeStore';
import useSharedStore from '@/store/shared/useSharedStore';
import useStatsStore from '@/store/stats/useStatsStore';
import useStoreStore from '@/store/store/useStoreStore';
import useSurveysStore from '@/store/surveys/useSurveysStore';
import useTermsAndConditionsStore from '@/store/termsAndConditions/useTermsAndConditionsStore';
import useLeaderboardStore from '@/store/useLeaderboardStore/useLeaderboardStore';
import useUserStore from '@/store/userStore/useUserStore';
import useTranslationsStore from '@/store/translations/useTranslationsStore';
import useBiometric from '../useBiometric/useBiometric';

export default function useLogout() {
  const { disabledBiometricOnStartup } = useBiometric();

  const router = useRouter();

  function resetStores(): void {
    useChallengesStore().$reset();
    useErrorStore().$reset();
    useKudosStore().$reset();
    useProfileStore().$reset();
    useQrCodeStore().$reset();
    useSharedStore().$reset();
    useStatsStore().$reset();
    useStoreStore().$reset();
    useSurveysStore().$reset();
    useTermsAndConditionsStore().$reset();
    useTokenStore().$reset();
    useTranslationsStore().$reset();
    useLeaderboardStore().$reset();
    useUserStore().$reset();
    useTokenStore().$reset();
  }

  function logout(): void {
    resetStores();

    usePostHogEvents().postHogReset();

    localStorage.clear();
    disabledBiometricOnStartup();
    i18n.global.locale.value = process.env.VUE_APP_I18N_LOCALE || Locale.US;

    router.push(Routes.LOGIN);
  }

  return {
    logout,
    resetStores,
    disabledBiometricOnStartup,
  };
}
