import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick", "onKeydown"]
const _hoisted_2 = {
  key: 0,
  class: "app-tabs__tab-icon-container"
}
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 1,
  class: "app-tabs__tab-label",
  "data-testid": "app-tab-label"
}

import { AppTab } from '@/shared/types/components';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import AppList from '../AppList/AppList.vue';

interface Props {
  tabs: AppTab[];
  outlined?: boolean;
  underlined?: boolean;
  replaceRoute?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTabs',
  props: {
    tabs: {},
    outlined: { type: Boolean },
    underlined: { type: Boolean },
    replaceRoute: { type: Boolean }
  },
  setup(__props: any) {



const route = useRoute();

const isCurrentPath = (path: string): boolean => path === route.path;

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(AppList, {
    id: "app-tabs",
    gap: "16px",
    items: _ctx.tabs,
    class: _normalizeClass([
      'app-tabs',
      {
        'app-tabs--outlined': _ctx.outlined,
        'app-tabs--underlined': _ctx.underlined,
      },
    ]),
    "data-testid": "app-tabs"
  }, {
    item: _withCtx(({ item }) => [
      _createElementVNode("div", {
        class: _normalizeClass([
          'app-tabs__tab',
          { 'app-tabs__tab--selected': isCurrentPath(item.path) },
        ]),
        onClick: item?.onClick,
        onKeydown: item?.onClick
      }, [
        _createVNode(_component_router_link, {
          class: "app-tabs__tab-link",
          to: item.path,
          "data-disabled": item?.disabled,
          replace: _ctx.replaceRoute
        }, {
          default: _withCtx(() => [
            (item?.icon)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (typeof item.icon === 'string')
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "app-tabs__tab-image",
                        src: item.icon,
                        alt: item.name,
                        "data-testid": "app-tab-icon-container"
                      }, null, 8, _hoisted_3))
                    : (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                        key: 1,
                        class: "app-tabs__tab-image",
                        alt: item.name,
                        "data-testid": "app-tab-icon-image"
                      }, null, 8, ["alt"]))
                ]))
              : _createCommentVNode("", true),
            (item?.name)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(t)(`tabs.${item.name}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to", "data-disabled", "replace"])
      ], 42, _hoisted_1)
    ]),
    _: 1
  }, 8, ["items", "class"]))
}
}

})