import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppEmptyState = _resolveComponent("AppEmptyState")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_AppEmptyState, {
      icon: _ctx.IconLostConnection,
      title: "noConnectionPage.noDataTitle",
      subtitle: "noConnectionPage.noDataDescription"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AppButton, {
          type: "empty",
          onClick: _ctx.refresh
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('generic.refresh')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["icon"])
  ]))
}