import { enUS, pt, de, es } from 'date-fns/locale';
import { Locale } from '@/shared/types/generic';
import { Locale as DateFnsLocale } from 'date-fns';

export default function getDateFnsLang(language: Locale): DateFnsLocale {
  const DateFnsLanguage: { [key in Locale]: DateFnsLocale } = {
    [Locale.PT]: pt,
    [Locale.US]: enUS,
    [Locale.DE]: de,
    [Locale.ES]: es,
  };

  return DateFnsLanguage[language];
}
