import axios from '@/plugins/http/axios';
import { StatsResponse } from '@/shared/types/stats';

export default {
  getDailyStats(request: {
    startTime: number;
    endTime: number;
  }): Promise<StatsResponse> {
    return axios.get('/stats', {
      params: request,
    });
  },
};
