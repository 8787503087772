import axios from '@/plugins/http/axios';
import { ScanQrCodeRequest } from '@/shared/types/qrCode';

export default {
  scanQrCode(request: ScanQrCodeRequest): Promise<void> {
    return axios.post('/ingestion/qr-code', request, {
      baseURL: process.env.VUE_APP_INGESTION_BASE_URL,
    });
  },
};
