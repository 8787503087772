import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "app-list" }

import {
  AppListFlexDirection,
  AppListFlexWrap,
} from '@/shared/types/components';
import { v4 } from 'uuid';

interface Props {
  items: T[];
  gap?: string;
  itemMargin?: string;
  itemPadding?: string;
  flexWrap?: AppListFlexWrap;
  flexDirection?: AppListFlexDirection;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppList',
  props: {
    items: {},
    gap: { default: 'unset' },
    itemMargin: { default: 'unset' },
    itemPadding: { default: 'unset' },
    flexWrap: { default: 'wrap' },
    flexDirection: { default: 'row' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "63ebcbf4": (_ctx.gap),
  "311be585": (_ctx.flexWrap),
  "82a201b8": (_ctx.flexDirection),
  "fe49107a": (_ctx.itemMargin),
  "387f8c9c": (_ctx.itemPadding)
}))



const uuid: string = v4();

const getItemKey = (index: number): string => `item_${uuid}_${index}`;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return _renderSlot(_ctx.$slots, "item", {
        key: getItemKey(index),
        class: "app-list__item",
        item: item,
        index: index
      })
    }), 128))
  ]))
}
}

})