import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile-banner" }
const _hoisted_2 = { class: "profile-banner__leading" }
const _hoisted_3 = {
  class: "profile-banner__body__title",
  "data-testid": "profile-banner-body-title"
}

import AppImage from '@/components/app/AppImage/AppImage.vue';
import {
  backgroundImageError,
  imageError,
  placeholderBackground,
  placeholderImage,
} from '@/shared/constants/icons';
import useProfileStore from '@/store/profile/useProfileStore';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileBanner',
  props: {
    title: {}
  },
  setup(__props: any) {



const profileStore = useProfileStore();

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "leading", { class: "profile-banner__body__leading" })
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)(_ctx.title)), 1),
    _createVNode(AppImage, {
      "image-url": _unref(profileStore)?.userProfileDetails?.activeAvatar.url || '',
      "image-alt": "Avatar",
      "image-placeholder": _unref(placeholderImage),
      "image-error": _unref(imageError),
      class: "profile-banner__avatar",
      "data-testid": "profile-banner-avatar"
    }, null, 8, ["image-url", "image-placeholder", "image-error"]),
    _createVNode(AppImage, {
      "image-url": _unref(profileStore)?.userProfileDetails?.activeBackground.url || '',
      "image-alt": "Background",
      "image-placeholder": _unref(placeholderBackground),
      "image-error": _unref(backgroundImageError),
      class: "profile-banner__background",
      "data-testid": "profile-banner-background"
    }, null, 8, ["image-url", "image-placeholder", "image-error"]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "profile-banner__overlay" }, null, -1))
  ]))
}
}

})