import axios from '@/plugins/http/axios';
import { ApiResponse, Routes } from '@/shared/types/generic';
import {
  ActivityEntryType,
  CustomAvatarAsset,
  CustomAvatarAssetCategory,
  GetSelectedCustomAvatarAssetsResponse,
  MetricActivityEntry,
  ProfileUpdateRequest,
  DetailedProfileUpdateRequest,
  UserProfileDetails,
  UserProfile,
  Activity,
  ActivityChallenge,
  ActivityMetric,
  ProfileSite,
  LanguageCode,
  InventoryResponse,
  LockedOutResponse,
} from '@/shared/types/profile';

export default {
  getProfile(): Promise<UserProfile> {
    return axios.get('/profiles');
  },

  getDetailedProfile(): Promise<UserProfileDetails> {
    return axios.get('/profiles/details');
  },

  getInventory(): Promise<InventoryResponse> {
    return axios.get('/profiles/inventory');
  },

  updateProfile(request: ProfileUpdateRequest): Promise<UserProfile> {
    return axios.patch('/profiles', request);
  },

  updateDetailedProfile(
    request: DetailedProfileUpdateRequest,
  ): Promise<UserProfileDetails> {
    return axios.patch('/profiles/details', request);
  },

  updateFtx(request: ProfileUpdateRequest): Promise<void> {
    return axios.patch('/profiles/ftx', request);
  },

  updateDeviceToken(deviceToken: string): Promise<void> {
    return axios.patch('/profiles/device-token', { deviceToken });
  },

  getProfileMetricActivity(
    activityEntryType?: ActivityEntryType,
    dateInterval?: number,
  ): Promise<MetricActivityEntry[]> {
    return axios.get('/profiles/activity', {
      params: { activityEntryType, dateInterval },
    });
  },

  getProfileSite(): Promise<ProfileSite> {
    return axios.get('/profiles/site');
  },

  getCustomAvatarAssetCategories(): Promise<
    ApiResponse<CustomAvatarAssetCategory[]>
  > {
    return axios.get('/avatar-category-assets');
  },

  getCustomAvatarAssetsByCategoryReference(
    customAvatarAssetCategoryReference: string,
  ): Promise<ApiResponse<CustomAvatarAsset[]>> {
    return axios.get(
      `/avatar-assets/category-assets/${customAvatarAssetCategoryReference}`,
    );
  },

  getSelectedCustomAvatarAssets(): Promise<
    ApiResponse<GetSelectedCustomAvatarAssetsResponse>
  > {
    return axios.get('/avatar-custom/users/selected-assets');
  },

  /**
   * Saves a custom avatar given the image binary and the selected asset references.
   * @param {string} imageBase64Binary A base 64 encoded string containing the custom avatar data.
   * @param {string} selectedAssetReferences A string containing the custom avatar asset references separated by semi colons.
   */
  saveCustomAvatar(
    imageBase64Binary: string,
    selectedAssetReferences: string[],
  ): Promise<void> {
    return axios.postForm('/avatar-custom', {
      imageBase64Binary,
      selectedAssetReferences: selectedAssetReferences.join(';'),
    });
  },

  getActivityScore(): Promise<Activity> {
    return axios.get(Routes.PROFILE_ACTIVITY_SCORE);
  },

  getActivityChallenges(): Promise<ApiResponse<ActivityChallenge[]>> {
    return axios.get(Routes.PROFILE_ACTIVITY_CHALLENGES);
  },

  getActivityMetrics(): Promise<ApiResponse<ActivityMetric[]>> {
    return axios.get(Routes.PROFILE_ACTIVITY_METRICS);
  },

  getLanguagesCodes(): Promise<ApiResponse<LanguageCode[]>> {
    return axios.get('/languages/codes');
  },

  saveProfileLanguage(codeLanguage: string): Promise<void> {
    return axios.post(`/users/language/${codeLanguage}`);
  },

  /**
   * Saves a login register in order to count the number of logins.
   */
  getCountLogin(): Promise<void> {
    return axios.get('/profiles/log-in');
  },

  getUserLockedOutInfo(email: string): Promise<LockedOutResponse> {
    return axios.get(`auth/locked-out/${email}`);
  },
};
