<template>
  <main class="page-content">
    <AppEmptyState
      :icon="IconLostConnection"
      title="noConnectionPage.noDataTitle"
      subtitle="noConnectionPage.noDataDescription"
    >
      <AppButton type="empty" @click="refresh">
        {{ t('generic.refresh') }}
      </AppButton>
    </AppEmptyState>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppEmptyState from '@/components/app/AppEmptyState/AppEmptyState.vue';
import AppButton from '@/components/app/AppButton/AppButton.vue';
import { Routes } from '@/shared/types/generic';
import IconLostConnection from '@/assets/icons/icon-lost-connection.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'NoConnectionView',

  components: { AppButton, AppEmptyState },

  setup() {
    const { t } = useI18n();

    return { t };
  },

  data() {
    return {
      IconLostConnection,
    };
  },

  methods: {
    refresh() {
      window.location.replace(Routes.CHALLENGES);
    },
  },
});
</script>

<style scoped lang="scss">
$content-padding: 16px;

.page-content {
  height: 100vh;
  display: flex;
  padding: $content-padding;
}
</style>
