<template>
  <div class="app-empty-state">
    <component
      :is="icon"
      name="empty-state"
      data-testid="app-empty-state-image"
    />
    <div class="app-empty-state__body" data-testid="app-empty-state-body">
      <p
        class="app-empty-state__body__title"
        data-testid="app-empty-state-title"
      >
        {{ t(title) }}
      </p>
      <p
        v-if="subtitle"
        class="app-empty-state__body__subtitle"
        data-testid="app-empty-state-subtitle"
      >
        {{ t(subtitle, subtitleParameters || []) }}
      </p>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { type Component } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  icon: Component;
  title: string;
  subtitle?: string;
  subtitleParameters?: Array<string>;
}

defineProps<Props>();

const { t } = useI18n();
</script>

<style scoped lang="scss">
.app-empty-state {
  height: 100%;
  gap: 16px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.app-empty-state__image {
  width: 300px;
  height: 220px;
}

.app-empty-state__body {
  display: flex;
  flex-direction: column;
}

.app-empty-state__body__title {
  color: $gray-800;
  font-size: 22px;
  font-weight: 800;
  line-height: 130%;
}

.app-empty-state__body__subtitle {
  color: $gray-800;
  font-size: 17px;
  font-weight: 400;
  line-height: 150%;
}
</style>
