import { defineStore } from 'pinia';

export default defineStore({
  id: 'shared',

  state: () => ({
    loaderState: false,
    loaderTarget: '',
    loaderTransparent: false,
    loaderText: '',
    loaderOpaque: false,
    isNotifyActive: false,
  }),

  actions: {
    setLoader(
      loaderState: boolean,
      loaderTarget?: string,
      loaderTransparent = false,
      loaderText = '',
      loaderOpaque = false,
    ) {
      this.loaderState = loaderState;
      this.loaderTarget = loaderTarget || '';
      this.loaderTransparent = loaderTransparent;
      this.loaderText = loaderText;
      this.loaderOpaque = loaderOpaque;
    },

    setIsNotifyActive(value: boolean): void {
      this.isNotifyActive = value;
    },
  },
});
