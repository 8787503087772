import { isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const isTodayTimezone = (
  date: Date | number,
  timezone: string,
): boolean => {
  return !timezone ? isToday(date) : isToday(utcToZonedTime(date, timezone));
};

export const isYesterdayTimezone = (
  date: Date | number,
  timezone: string,
): boolean => {
  return !timezone
    ? isYesterday(date)
    : isYesterday(utcToZonedTime(date, timezone));
};

export const isThisWeekTimezone = (
  date: Date | number,
  timezone: string,
): boolean => {
  return !timezone
    ? isThisWeek(date)
    : isThisWeek(utcToZonedTime(date, timezone));
};

export const isThisYearTimezone = (
  date: Date | number,
  timezone: string,
): boolean => {
  return !timezone
    ? isThisYear(date)
    : isThisYear(utcToZonedTime(date, timezone));
};
