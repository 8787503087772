import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "app-layout-wrapper",
  class: "app-layout-wrapper",
  "data-testid": "app-layout-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock("div", {
    "data-testid": "app-layout",
    class: _normalizeClass(['app-layout', `app-layout--${_ctx.layoutType}`])
  }, [
    (_ctx.showHeader)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[0] || (_cache[0] = _createElementVNode("div", {
            class: "app-layout__gradient",
            "data-testid": "app-layout-gradient"
          }, null, -1)),
          _createVNode(_component_AppHeader)
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _withDirectives(_createVNode(_component_AppFooter, null, null, 512), [
      [_vShow, _ctx.showFooter]
    ])
  ], 2))
}