import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]

import { IconSettings } from '@/shared/types/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'icon-profile-line',
  props: {
    color: { default: '#3C3B39' },
    colorSecondary: {},
    colorTertiary: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M18 3H6C4.35 3 3 4.35 3 6V18C3 19.65 4.35 21 6 21H18C19.65 21 21 19.65 21 18V6C21 4.35 19.65 3 18 3ZM19 18C19 18.55 18.55 19 18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H18C18.55 5 19 5.45 19 6V18Z",
      fill: props.color
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M14.3503 14.2403C13.1803 15.2103 10.8103 15.2103 9.65029 14.2403C9.23029 13.8803 8.59029 13.9403 8.24029 14.3603C7.88029 14.7803 7.94029 15.4103 8.36029 15.7703C9.30029 16.5603 10.6303 17.0103 12.0103 17.0103C13.3903 17.0103 14.7103 16.5603 15.6503 15.7803C16.0803 15.4303 16.1303 14.8003 15.7803 14.3803C15.4303 13.9603 14.7903 13.9003 14.3703 14.2603L14.3503 14.2403Z",
      fill: props.color
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M9.99893 10.49C9.78893 10.49 9.57893 10.42 9.39893 10.29C8.87893 9.9 8.11893 9.9 7.59893 10.29C7.15893 10.62 6.52893 10.53 6.19893 10.09C5.86893 9.65 5.95893 9.02 6.39893 8.69C7.61893 7.77 9.38893 7.77 10.5989 8.69C11.0389 9.02 11.1289 9.65 10.7989 10.09C10.5989 10.35 10.2989 10.49 9.99893 10.49V10.49Z",
      fill: props.color
    }, null, 8, _hoisted_4),
    _createElementVNode("path", {
      d: "M16.9989 10.49C16.7889 10.49 16.5789 10.42 16.3989 10.29C15.8789 9.9 15.1189 9.9 14.5989 10.29C14.1589 10.62 13.5289 10.53 13.1989 10.09C12.8689 9.65 12.9589 9.02 13.3989 8.69C14.6189 7.77 16.3889 7.77 17.5989 8.69C18.0389 9.02 18.1289 9.65 17.7989 10.09C17.5989 10.35 17.2989 10.49 16.9989 10.49V10.49Z",
      fill: props.color
    }, null, 8, _hoisted_5)
  ]))
}
}

})