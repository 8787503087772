import { defineStore } from 'pinia';
import { StatsResponse } from '@/shared/types/stats';
import statsService from '@/shared/services/statsService/statsService';

interface State {
  dailyStats?: StatsResponse;
}

export default defineStore({
  id: 'stats',

  state: (): State => ({
    dailyStats: undefined,
  }),

  getters: {
    hasDailyMetrics: (state) => !!state.dailyStats?.metrics.length,
  },

  actions: {
    async loadDailyStats() {
      try {
        const start = new Date();
        start.setHours(0, 0, 0, 0);

        const end = new Date();
        end.setHours(23, 59, 59, 999);

        this.dailyStats = await statsService.getDailyStats({
          startTime: start.getTime(),
          endTime: end.getTime(),
        });
      } catch (e) {
        this.dailyStats = undefined;
      }
    },
  },
});
