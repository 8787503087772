import termsAndConditionsService from '@/shared/services/termsAndConditionsService/termsAndConditionsService';
import { defineStore } from 'pinia';

interface State {
  termsAndConditions: string;
}

export default defineStore({
  id: 'termsAndConditions',

  state: (): State => ({
    termsAndConditions: '',
  }),

  actions: {
    async getTermsAndConditions() {
      try {
        const response = await termsAndConditionsService.getTermsAndConditions(
          Date.now(),
        );

        const blob = new Blob([response], { type: 'application/pdf' });

        const objectUrl = URL.createObjectURL(blob);

        this.termsAndConditions = objectUrl;
      } catch (error) {
        this.termsAndConditions = '';
      }
    },

    async acceptTermsAndConditions() {
      await termsAndConditionsService.acceptTermsAndConditions();
    },
  },
});
