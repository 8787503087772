import {
  StoreItem,
  PurchasedItem,
  CategorizedOption,
  StoreRequest,
  CollectablesRequest,
  CollectablesResponse,
  CollectableType,
} from '@/shared/types/store';
import { ApiResponse } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';

export default {
  getStoreItems(): Promise<ApiResponse<{ storeItems: StoreItem[] }>> {
    return axios.get('/stores');
  },

  getStoreItemsByType(
    request: StoreRequest,
  ): Promise<ApiResponse<{ storeItems: StoreItem[] }>> {
    return axios.get('/stores', { params: request });
  },

  purchaseStoreItem(storeItemReference: string): Promise<{ coins: number }> {
    return axios.post('/stores/buy', { storeItemReference });
  },

  getPurchasedItems(): Promise<ApiResponse<PurchasedItem[]>> {
    return axios.get('/purchases/worker');
  },

  getCollectablesCategories(
    collectableType: string,
    limit: number,
  ): Promise<ApiResponse<CategorizedOption[]>> {
    return axios.get(`/stores/collectables/${collectableType}?limit=${limit}`);
  },

  getPrizes(): Promise<ApiResponse<StoreItem[]>> {
    return axios.get('/stores/prizes');
  },

  getCollectables(request: CollectablesRequest): Promise<CollectablesResponse> {
    return axios.get('/stores/detail', { params: request });
  },

  getCollectablesTypes(): Promise<ApiResponse<CollectableType[]>> {
    return axios.get('/item-types/configs');
  },
};
