import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

import { IconSettings } from '@/shared/types/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'icon-leaderboard-line',
  props: {
    color: { default: '#3C3B39' },
    colorSecondary: {},
    colorTertiary: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M17 21H6.99998C6.53998 21 6.13998 20.69 6.02998 20.24L3.02998 8.24C2.91998 7.8 3.11998 7.35 3.50998 7.13C3.89998 6.91 4.38998 6.97 4.70998 7.29L8.14998 10.73L11.08 3.62C11.23 3.25 11.6 3 12 3C12.4 3 12.77 3.24 12.92 3.62L15.85 10.73L19.29 7.29C19.61 6.97 20.1 6.9 20.49 7.13C20.88 7.35 21.08 7.81 20.97 8.24L17.97 20.24C17.86 20.68 17.46 21 17 21V21ZM7.77998 19H16.22L18.16 11.26L16.21 13.21C15.97 13.45 15.63 13.55 15.31 13.48C14.98 13.41 14.71 13.19 14.58 12.88L12 6.63L9.41998 12.88C9.28998 13.19 9.01998 13.41 8.68998 13.48C8.35998 13.55 8.01998 13.44 7.78998 13.21L5.83998 11.26L7.77998 19Z",
      fill: props.color
    }, null, 8, _hoisted_2)
  ]))
}
}

})