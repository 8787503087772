import { StatusStaticReference } from './generic';

export enum ChallengeAudienceStaticReference {
  INDIVIDUAL = 'A_1',
  TEAM = 'A_2',
}

export enum ChallengeModeStaticReference {
  TEAM_DUEL = 'CM_5',
  TEAM_VS_TEAM = 'CM_4',
  TOGETHER_AS_TEAM = 'CM_3',
  TEAM_COOPERATION = 'CM_6',
  PLAYER_VS_PLAYER = 'CM_1',
  SINGLE_PLAYER = 'CM_7',
}

export enum ChallengeTypeStaticReference {
  CLASSIC = 'CT_3',
  FASTEST = 'CT_4',
  REACHED_AS_TEAM = 'CT_5',
  ACHIEVER = 'CT_6',
}

export enum ChallengeGoalStaticReference {
  TARGET = 'CG_6',
  MOST_POINTS_WIN = 'CG_4',
  LEAST_POINTS_WIN = 'CG_5',
}

export interface ChallengeType {
  name: string;
  imageUrl: string;
  reference: string;
  description: string;
  staticReference: ChallengeTypeStaticReference;
}

export interface ChallengeMode {
  name: string;
  imageUrl: string;
  reference: string;
  description: string;
  staticReference: ChallengeModeStaticReference;
}

export interface ChallengeGoal {
  name: string;
  reference: string;
  staticReference: ChallengeGoalStaticReference;
}

export enum ChallengeDuelResults {
  WIN = 'win',
  LOSE = 'lose',
  TIE = 'tie',
}

export interface Team {
  name: string;
  image: string;
  yourTeam: boolean;
  units: number;
  members?: number;
  imageUrl: string;
  numberOfUsers?: number;
  result?: ChallengeDuelResults;
}

export interface LastCompletedChallenges {
  totalCurrentCoins: number;
  totalCurrentPoints: number;
  earnedCoins?: number;
  earnedPoints?: number;
  finishedChallenges?: number;
  numberOfWins?: number;
  challengeMetricOrCategoryName?: string;
  challengeUnitMeasureName?: string;
  challengePosition?: number;
  challengeDays?: number;
  challengeNumberOfUnits?: number;
  challengeResolutionStatus?: ChallengeDuelResults;
}

export interface Challenge {
  reference: string;
  name: string;
  imageUrl: string;
  typeStaticReference: ChallengeTypeStaticReference;
  goalStaticReference?: ChallengeGoalStaticReference;
  modeStaticReference: ChallengeModeStaticReference;
  unitOfMeasureName: string;
  targetAmount?: number;
  audienceStaticReference: ChallengeAudienceStaticReference;
  startTime: number;
  endTime: number;
  statusStaticReference: StatusStaticReference;
  metricName?: string;
  categoryName?: string;
  potentialCoins?: number;
  coinsPerUnit?: number;
  coinsEarned?: number;
  goalStatement: string;
}

export interface LeaderBoardPlacement {
  position: number;
  name?: string;
  urlAvatar: string;
  currentUser: boolean;
  units: number;
  username: string;
}

export enum RecurrencyConfigsOptions {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface ChallengeDetails extends Challenge {
  numberOfParticipants?: number;
  description: string;
  metricDescription?: string;
  categoryDescription?: string;
  unitsFromNextScore?: number;
  leaderboard?: LeaderBoardPlacement[];
  pointsPerDay?: number;
  cadence?: RecurrencyConfigsOptions;
  teams?: Team[];
  units?: number;
  totalPoints: number;
  totalCoins: number;
  pointsEarned?: number;
  currentCoins?: number;
  conclusionDate?: number;
  conclusionReason?: string;
  calculationTypeName: string;
  goalStatement: string;
}

export interface ChallengeCombinations {
  isIndividualCompetition: boolean;
  isIndividualRace: boolean;
  isIndividualCooperation: boolean;
  isIndividualAchiever: boolean;
  isTeamCompetition: boolean;
  isTeamRace: boolean;
  isTeamCooperation: boolean;
  isCompetitive: boolean;
  isCooperative: boolean;
}

export interface ChallengeObjectsStaticReferenceMap {
  mode: Record<string, ChallengeModeStaticReference>;
  type: Record<string, ChallengeTypeStaticReference>;
}
